@import "variables";
@import "bootstrap";

.uppercase{
  text-transform: uppercase;
}

p{
  line-height: 150%;
}

small{
  color: #9C9C9C;
  font-size: 12px;
}

.strike{
  text-decoration: line-through;
}

.img-border{
  border: 1px solid #dedede;
  border-bottom: none;
}

.home-text{
  color: #888888;
}

.grocery-text{
  font-size: 20px;
  
}

.block{
  
  &.block-home{
    background: $navbar-default-bg;
    overflow: hidden;
    
    .row{
      padding-top: 100px;
    }
  }
  
  &.block-feature{
    padding-top: 100px;
    padding-bottom: 0; 
    border-bottom: 1px solid #ececec; 
    overflow: hidden;
  }
  
  &.block-buynow{
    padding: 50px;
    border-bottom: 1px solid #ececec;
    background-color: $brand-primary;
    
    .btn-primary{
      background-color: #fff;
      color: $brand-primary;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
    }
  }

  &.block-buynow{
    .btn-primary:hover{
      background-color: $brand-primary;
      color: #fff;
    }
  }
}

.row.feature{
  padding: 80px 0;
}

.blue{
  color: $blue;
}

.red{
  color: $red;
}

.yellow{
  color: $yellow;
}

.green{
  color: $green;
}

.blue-bg{
  background-color: $blue;
}

.red-bg{
  background-color: $red;
}

.yellow-bg{
  background-color: $yellow;
}

.green-bg{
  background-color: $green;
}

.grey-bg{
  background-color: $navbar-default-bg;
}

.icon-box{
  font-size: 100px;
}

.circle-100{
  width: 100px; 
  height: 100px; 
  line-height: 100px; 
  border-radius: 50%;
  font-size: 30px;
  transform:rotate(15deg);
}

.badge{
  position: absolute; 
  right: -50px; 
  top: -50px; 
  color: #fff;
}

ul.blocks{
  padding: 0;
  display: inline;
  float: left;
  margin-right: 10px;
}

ul.blocks li{
  width: 24px;
  height: 6px;
  list-style: none;
}


blockquote{
  font-size: 25px;
  border: none;
}

cite{
  font-style: normal;  
  font-size: 18px;
}

cite::before{
  content: "\2014\ ";
}

footer{
  background-color: #323232;
  color: #c3c3c3;
  font-size: 14px;
}

.img-testimony{
  float:left;
  
}

.typed-cursor{
  opacity: 1;
  -webkit-animation: blink 0.7s infinite;
  -moz-animation: blink 0.7s infinite;
  animation: blink 0.7s infinite;
}
@keyframes blink{
  0% { opacity:1; }
  50% { opacity:0; }
  100% { opacity:1; }
}
@-webkit-keyframes blink{
  0% { opacity:1; }
  50% { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes blink{
  0% { opacity:1; }
  50% { opacity:0; }
  100% { opacity:1; }
}

@media only screen and (max-device-width: $container-sm){
  .block.block-buynow{
    background-color: #fff;
    padding: 50px 0;
    .btn{
      height: 100px;
      white-space: normal;
      background-color: $brand-primary;
      color: #fff;
    }
  }

  .intro{
    text-align: center;
    .btn-buynow{
      margin: 100px 0;
    }
  }
  
  .badge{
    right: -5px; 
  }
  
  .block.block-testimony{
    text-align: center;
    padding-top: 50px;
    
    img{
      width: 100px;
    }
  }
  
  .img-testimony{
    float:none;
    margin: 0 auto;
  }
  
  .navbar-brand{
    padding: 10px 0;
  }

  .block.block-home .row{
    padding-top: 0px;
  }

  .navbar-nav ul{
    background: #ffffff;
  }
  
  .navbar-nav li{
  }
  
  .tagline{ display: none}
}

.faq{
  margin-bottom: 100px;
}

.faq h3:first-child, .docs h2:first-child{
  margin-top: 0px;
}

.faq h3, .docs h2{
  margin-top: 80px;
}

.docs{
  font-size: 15px;
  line-height: 150%;
}

.docs h3{
  margin-top: 40px;
  border-bottom: 1px solid #dedede;
}

.docs h3:first-child{
  margin-top: 0px;
}

pre{
  white-space: pre;
  word-break: normal;
  word-wrap: normal;
}